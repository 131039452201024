import * as yup from 'yup';

export const schema = yup
  .object({
    pessoa: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    dta_inicio: yup.string().required().nullable(),
    dta_final: yup.string().required().nullable(),
  })
  .required();
