import api from '~/services/api';

const aceiteDePagamentoA = {
  getContasAPagar: async (requestParams: any) => {
    try {
      const { data } = await api.get('/aceite-contas-apagar', {
        params: requestParams,
      });

      return data;
    } catch (error) {
      return error;
    }
  },
};

export default aceiteDePagamentoA;
