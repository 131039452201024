import { TableContainer } from '@material-ui/core';
import React from 'react';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { Col, Row } from 'react-bootstrap';
import { FaBarcode } from 'react-icons/fa';
import Separator from '~/components/Separator';
import { Subtotal } from '../../styles';

export const AceiteInfos: React.FC = () => {
  const { infoAceite, applyInfo, handleOpenModal, isTitulos, subTotal } =
    useAceiteDePagamento();
  return (
    <>
      <Row>
        <Col md={3} sm={12} className="mt-2">
          <button
            type="button"
            className="button-aceite"
            onClick={() => handleOpenModal()}
            disabled={!isTitulos}
          >
            <FaBarcode size={25} />
            Capturar Aceite
          </button>
        </Col>
        <Col md={6} sm={12}>
          <Separator />
        </Col>
        <Col md={3} sm={12}>
          <Subtotal>
            <h5>
              Selecionados:{' '}
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(subTotal)}
            </h5>
          </Subtotal>
        </Col>
      </Row>
      <Row>
        {applyInfo && (
          <Col md={12} sm={12}>
            <TableContainer>
              <table className="table">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th>Vencimento</th>
                    <th>NºFat/Dup</th>
                    <th>Captura</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: '37px' }}>
                    <td>{infoAceite.tipo_aceite?.label}</td>
                    <td className="alignNumber">{`R$ ${infoAceite.val_captura}`}</td>
                    <td className="alignDate">
                      {createDateWithoutTimezone(
                        infoAceite.dta_vencimento,
                      ).toLocaleDateString('pt-BR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </td>
                    <td className="alignNumber">{infoAceite.num_fat_dup}</td>
                    <td>{infoAceite.captura}</td>
                  </tr>
                </tbody>
              </table>
            </TableContainer>
          </Col>
        )}
      </Row>
    </>
  );
};
