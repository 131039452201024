import React from 'react';
import { BtnGroupContainer, GroupItem } from '../../styles';
import ButtonAtlaskit from '@atlaskit/button';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';
import { Col } from 'react-bootstrap';
import ToggleDefault from '~/components/ToggleDefault';

export const GroupButtons: React.FC = () => {
  const {
    tipoPeriodo,
    setTipoPeriodo,
    isTitulos,
    com_aceite,
    setValue,
    setCom_aceite,
  } = useAceiteDePagamento();
  return (
    <>
      <Col md={2} sm={12}>
        <GroupItem style={{ height: '60px', marginTop: '15px' }}>
          <label
            className="label"
            htmlFor="toggle-controlled"
            style={{ marginTop: '5px' }}
          >
            Com Aceite?
          </label>
          <ToggleDefault
            size="large"
            labelText=""
            setChecked={com_aceite}
            disabled={isTitulos}
            onSwitch={() => {
              setValue('aceite', !com_aceite);
              setCom_aceite(!com_aceite);
              if (!com_aceite) {
                setTipoPeriodo({ value: 2, label: 'Aceite' });
              } else {
                setTipoPeriodo({ value: 0, label: 'Entrada' });
              }
            }}
          />
        </GroupItem>
      </Col>
      <Col md={6} sm={12}>
        <GroupItem style={{ height: '60px', marginTop: '20px' }}>
          <label
            className="label"
            htmlFor="toggle-controlled"
            style={{
              marginTop: '-2px',
              marginRight: '0.3vw',
              fontSize: '1.4vw',
            }}
          >
            Período
          </label>
          <BtnGroupContainer>
            <ButtonAtlaskit
              key="ContaContabil"
              className={
                tipoPeriodo.value === 0
                  ? 'btn-group btn-group-active'
                  : 'btn-group'
              }
              value="0"
              style={{
                height: '37px',
                marginLeft: '0px',
                paddingLeft: '0px',
                borderRadius: '4px 0px 0px 4px',
                width: '35%',
              }}
              type="button"
              isDisabled={isTitulos}
              onClick={() => {
                setTipoPeriodo({ value: 0, label: 'Entrada' });
              }}
            >
              Entrada
            </ButtonAtlaskit>
            <ButtonAtlaskit
              key="reduzida"
              className={
                tipoPeriodo.value === 1
                  ? 'btn-group btn-group-active'
                  : 'btn-group'
              }
              style={{
                color: '#fff',
                height: '37px',
                width: '30%',
                borderLeft: '2px solid #fff',
                borderRight: '2px solid #fff',
              }}
              type="button"
              isDisabled={isTitulos}
              onClick={() => {
                setTipoPeriodo({ value: 1, label: 'Vencimento' });
              }}
            >
              Vencimento
            </ButtonAtlaskit>
            <ButtonAtlaskit
              key="desc"
              className={
                tipoPeriodo.value === 2
                  ? 'btn-group btn-group-active'
                  : 'btn-group'
              }
              style={{
                borderRadius: '0px 4px 4px 0px',
                height: '37px',
                width: '35%',
              }}
              isDisabled={isTitulos}
              type="button"
              onClick={() => {
                setTipoPeriodo({ value: 2, label: 'Aceite' });
              }}
            >
              Aceite
            </ButtonAtlaskit>
          </BtnGroupContainer>
        </GroupItem>
      </Col>
    </>
  );
};
