import React, { createContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';
import { codBarras2LinhaDigitavel, validarBoleto } from '~/utils/boletos';
import api from '~/services/api';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { transformAsCurrency } from '~/utils/functions';
import { lojaUnica } from '~/utils/lojaUnica';
import { isDate } from 'date-fns';
import aceiteDePagamentoApi from '../services';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../validations/FormDataValidations';
import { schemaModal } from '../validations/ModalDataValidations';
import {
  AceiteDePagamentoContextProps,
  SelectProps,
  TitulosProps,
} from '../types/context';

export const AceiteDePagamentoContext = createContext(
  {} as AceiteDePagamentoContextProps,
);

export const AceiteDePagamentoProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    register,
    setValue,
    control,
    getValues,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    register: registerModal,
    handleSubmit: handleSubmitModal,
    setValue: setValueModal,
    control: controlModal,
    reset: resetModal,
    formState: formStateModal,
    getValues: getValuesModal,
    setError: setErrorModal,
    clearErrors: clearErrorsModal,
    formState: { errors: errorsModal },
    watch: watchModal,
  } = useForm({
    resolver: yupResolver(schemaModal),
    reValidateMode: 'onChange',
  });
  const [init, setInit] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTela, setLoaderTela] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  // FORMUMALIRO ACEITE
  const [lojas, setLojas] = useState<number | Array<number>>([]);
  const [com_aceite, setCom_aceite] = useState<boolean>(false);
  const [tipoPeriodo, setTipoPeriodo] = useState<SelectProps>({
    value: 0,
    label: 'Entrada',
  });
  // MODAL ACEITE BOLETO
  const [isValid, setIsValid] = useState<boolean>(false);
  const [titulosApagar, setTitulosApagar] = useState<TitulosProps[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [isTitulos, setIsTitulos] = useState<boolean>(false);
  const [applyInfo, setApplyInfo] = useState<boolean>(false);

  // INFO RODAPÉ ACEITE
  const [infoAceite, setInfoAceite] = useState({
    captura: '',
    tipo_aceite: {
      value: null,
      label: '',
    },
    val_captura: '',
    dta_vencimento: '',
    num_fat_dup: '',
    dta_emissao: '',
    conta: {
      value: null,
      label: '',
    },
    finalizadora: {
      value: null,
      label: '',
    },
    despesa: {
      value: null,
      label: '',
    },
  });

  const queryClient = useQueryClient();

  const isValidDate = (date: Date) =>
    isDate(date) && !Number.isNaN(date.getTime());

  const formatterParams = (data: any) => {
    let { val_parcela } = data;
    if (typeof val_parcela === 'undefined' || !val_parcela)
      val_parcela = undefined;
    else val_parcela = transformAsCurrency(val_parcela);

    const num_docto =
      data.num_docto !== undefined && data.num_docto > 0
        ? data.num_docto
        : undefined;

    return {
      lojas,
      cod_pessoa: data.pessoa?.value,
      num_docto,
      val_parcela,
      com_aceite,
      tipo_periodo: tipoPeriodo.value,
      dta_ini: data.dta_inicio,
      dta_fim: data.dta_final,
    };
  };

  const formatterResponseContas = (data: any) => {
    const result = data.map((item: TitulosProps) => {
      return {
        cod_parcela_pagar: item.cod_parcela_pagar,
        cod_conta_pagar: item.cod_conta_pagar,
        flg_boleto: com_aceite,
        cod_loja: item.cod_loja,
        num_docto: item.num_docto,
        num_nf: item.num_nf,
        num_serie: item.num_nf,
        des_finalizadora: item.des_finalizadora,
        cod_finalizadora: item.cod_finalizadora,
        val_parcela: item.val_parcela,
        dta_entrada: createDateWithoutTimezone(
          item.dta_entrada,
        ).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        dta_vencimento: createDateWithoutTimezone(
          item.dta_vencimento,
        ).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
      };
    });
    return result;
  };
  const sumTotal = (itens: any) => {
    const sum = itens.reduce(
      (acc: any, item: any) => (item.flg_boleto ? acc + item.val_parcela : acc),
      0,
    );
    return sum;
  };

  const validateData = (dataInicio: any, dataFim: any) => {
    const isStartDateAfterEndDate = (startDate: Date, endDate: Date) =>
      isValidDate(startDate) && isValidDate(endDate) && startDate > endDate;

    const startDate = new Date(dataInicio);
    const endDate = new Date(dataFim);

    return isStartDateAfterEndDate(startDate, endDate);
  };

  const getTitulosAPagar = handleSubmit(async (inputData) => {
    setTitulosApagar([]);
    setInit(true);
    setLoader(true);

    if (validateData(inputData.dta_inicio, inputData.dta_final)) {
      toast.warning('Data Final deve ser maior ou igual a Data de Início');
      setInit(false);
      setLoader(false);
      return;
    }

    try {
      const requestParams = formatterParams(inputData);
      const { data } = await aceiteDePagamentoApi.getContasAPagar(
        requestParams,
      );
      if (data.length > 0) {
        const dataFormatted = formatterResponseContas(data);
        setTitulosApagar(dataFormatted);
        const total = sumTotal(dataFormatted);
        setSubTotal(total);
        setIsTitulos(true);
      } else {
        setTitulosApagar([]);
        setSubTotal(0);
        toast.warning(
          'Nenhum título encontrado conforme critérios informados.',
        );
      }
    } catch (error) {
      setInit(false);
      setLoader(false);
    } finally {
      setInit(false);
      setLoader(false);
    }
  });

  async function handleCheck(cod_parcela_pagar: number) {
    const titulos = titulosApagar;
    const index = titulos.findIndex(
      (item) =>
        item.cod_parcela_pagar.toString() === cod_parcela_pagar.toString(),
    );

    titulos[index].flg_boleto = !titulos[index].flg_boleto;
    setTitulosApagar(titulos);
    let sum = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of titulos) {
      sum = item.flg_boleto ? sum + item.val_parcela : sum + 0;
    }
    const roundedSum = Math.round(sum * 100) / 100;
    setSubTotal(roundedSum);
  }

  function clearFormModal() {
    resetModal();
    setTimeout(() => {
      setValueModal('tipo_aceite', '');
      setValueModal('val_captura', '');
      setValueModal('dta_vencimento', '');
      setValueModal('dta_emissao', '');
      setValueModal('num_fat_dup', '');
      setValueModal('conta', '');
      setValueModal('finalizadora', '');
      setValueModal('despesa', '');
    }, 10);
  }

  function clearForm() {
    setInit(false);
    setLoader(false);
    setOpenModal(false);
    // FORMUMALIRO ACEITE
    reset();
    setCom_aceite(false);
    setTipoPeriodo({
      value: 0,
      label: 'Entrada',
    });
    setTimeout(() => {
      setValue('pessoa', {
        label:
          'Informe pelo menos 3 caracteres do Cliente/Fornecedor/Loja/Transportadora',
        value: null,
      });
      setValue('num_docto', '');
      setValue('val_parcela', '');
    }, 30);
    // MODAL ACEITE BOLETO
    resetModal();
    setTimeout(() => {
      setValueModal('tipo_aceite', '');
      setValueModal('val_captura', '');
      setValueModal('dta_vencimento', '');
      setValueModal('dta_emissao', '');
      setValueModal('num_fat_dup', '');
      setValueModal('conta', '');
      setValueModal('finalizadora', '');
      setValueModal('despesa', '');
    }, 10);
    setApplyInfo(false);
    setIsValid(false);
    setTitulosApagar([]);
    setSubTotal(0);
    setIsTitulos(false);
    setInfoAceite({
      captura: '',
      tipo_aceite: {
        value: null,
        label: '',
      },
      val_captura: '',
      dta_vencimento: '',
      num_fat_dup: '',
      dta_emissao: '',
      conta: {
        value: null,
        label: '',
      },
      finalizadora: {
        value: null,
        label: '',
      },
      despesa: {
        value: null,
        label: '',
      },
    });
  }

  const onSave = async () => {
    const {
      val_captura,
      captura,
      tipo_aceite,
      dta_vencimento,
      num_fat_dup,
      dta_emissao,
      conta,
      finalizadora,
      despesa,
    } = getValuesModal();
    const { pessoa } = getValues();
    try {
      setLoaderTela(true);
      setInit(true);
      if (com_aceite) {
        const titulosSelecionados = titulosApagar.filter(
          (item) => item.flg_boleto === false,
        );
        if (!(titulosSelecionados.length > 0)) {
          return toast.warning(
            'Nenhum título foi desmarcado para desfazer o aceite.',
          );
        }
        const data = {
          titulosApagar: titulosSelecionados,
        };
        const result = await api.post(
          '/aceite-contas-apagar/desvincular',
          data,
        );
        if (result.data.success) {
          clearForm();
          return toast.success(result.data.message);
        }
      } else {
        let capturaVal = val_captura;
        if (!capturaVal) {
          return;
        }
        if (!capturaVal) {
          setOpenModal(true);
          return;
        }
        const titulosSelecionados = titulosApagar.filter(
          (item) => item.flg_boleto === true,
        );
        if (!(titulosSelecionados.length > 0)) {
          return toast.warning('Nenhum Titulo Selecionado');
        }

        if (capturaVal.includes('.')) {
          capturaVal = capturaVal.replace('.', '');
        }
        if (Number(capturaVal.replace(',', '.')) !== subTotal) {
          setLoaderTela(false);
          return toast.warning(
            `Valor do aceite deve ser igual à soma dos títulos selecionados`,
          );
        }
        const cod_loja = lojaUnica(lojas);
        const data = {
          cod_loja,
          cod_pessoa: pessoa.cod_pessoa,
          num_docto: num_fat_dup,
          dta_emissao,
          cod_categoria: despesa.value,
          cod_cc: conta.value,
          cod_barra_aceite: captura,
          val_parcela: capturaVal.replace(',', '.'),
          dta_venc: dta_vencimento,
          tipo_aceite: tipo_aceite.value,
          finalizadora,
          titulosApagar: titulosSelecionados,
        };
        const result = await api.post('/aceite-contas-apagar/vincular', data);
        if (result.data.success) {
          clearForm();
          return toast.success(result.data.message);
        }
      }
    } finally {
      setLoaderTela(false);
    }
  };

  const apply = handleSubmitModal((modalData) => {
    setApplyInfo(true);
    if (validateData(modalData.dta_emissao, modalData.dta_vencimento)) {
      toast.warning(
        'Data de Emissão deve ser menor ou igual a Data de Vencimento',
      );
      return;
    }
    setInfoAceite({
      captura: modalData.captura,
      tipo_aceite: modalData.tipo_aceite,
      val_captura: modalData.val_captura,
      dta_vencimento: modalData.dta_vencimento,
      num_fat_dup: modalData.num_fat_dup,
      dta_emissao: modalData.dta_emissao,
      conta: modalData.conta,
      finalizadora: modalData.finalizadora,
      despesa: modalData.despesa,
    });
    setOpenModal(false);
  });

  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  function validacaoBoleto() {
    const captura = getValuesModal('captura');
    setIsValid(true);
    const optionsAceite = [
      { label: 'Simplificado', value: 0 },
      { label: 'Boleto', value: 1 },
      { label: 'Concessionária', value: 2 },
    ];

    const capturaBoleto =
      captura?.length === 44
        ? codBarras2LinhaDigitavel(captura || '', false)
        : captura;
    const validar: any = validarBoleto(capturaBoleto || '');
    if (validar.sucesso) {
      toast.success(validar.mensagem);

      /*
    Preenchendo formulario com dados do boleto
    */

      const aceiteObject = optionsAceite.find(
        (option) => option.value === validar.tipoAceite,
      );
      setValueModal('tipo_aceite', aceiteObject || { label: '', value: 0 });
      setValueModal('val_captura', validar.valor);
      setValueModal(
        'dta_vencimento',
        validar.vencimento.toLocaleDateString('en-CA'),
      );
    } else {
      setValueModal('val_captura', '');
      setValueModal('dta_vencimento', '');
      toast.warning(validar.mensagem);

      if (validar.tipoAceite === 0) {
        setValueModal('tipo_aceite', { label: 'Simplificado', value: 0 });
      }
    }
  }

  const handleCloseModal = () => {
    const values = getValuesModal();
    Object.entries(infoAceite).forEach(([key, value]) => {
      if (values[key] !== value) {
        setValueModal(key as keyof typeof infoAceite, value);
      }
    });
    setOpenModal(false);
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 173) {
        return true;
      }
    }

    return false;
  };

  useHotkeys(
    'ctrl+alt+s',
    () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        onSave();
      }
    },
    [],
  );

  const keyMap = {
    hkSalvar: 'ctrl+alt+s',
  };

  const keyHandlers = {
    hkSalvar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        onSave();
      }
    },
  };
  return (
    <AceiteDePagamentoContext.Provider
      value={{
        // Estados
        init,
        setInit,
        loader,
        setLoader,
        loaderTela,
        setLoaderTela,
        openModal,
        setOpenModal,
        lojas,
        setLojas,
        com_aceite,
        setCom_aceite,
        tipoPeriodo,
        setTipoPeriodo,
        isValid,
        setIsValid,
        titulosApagar,
        setTitulosApagar,
        subTotal,
        setSubTotal,
        isTitulos,
        setIsTitulos,

        // Funções
        getTitulosAPagar,
        handleCheck,
        clearForm,
        onSave,
        apply,
        validacaoBoleto,
        handleCloseModal,
        validaTelaAtalho,
        keyMap,
        keyHandlers,
        handleOpenModal,
        register,
        setValue,
        control,
        getValues,
        reset,
        handleSubmit,
        errors,
        clearErrors,
        registerModal,
        handleSubmitModal,
        setValueModal,
        controlModal,
        resetModal,
        getValuesModal,
        errorsModal,
        clearErrorsModal,
        watchModal,
        applyInfo,
        setApplyInfo,
        clearFormModal,
        infoAceite,
      }}
    >
      {children}
    </AceiteDePagamentoContext.Provider>
  );
};
