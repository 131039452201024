import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Loja from '~/components/Loja';
import {
  BuscaParceiro,
  InputNumber2,
  InputMoney,
} from '~/components/NovosInputs';
import { SelectType } from '~/pages/DevolucaoESaidaNFDeFornecedor/protocols';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';

export const SearchInputs: React.FC = () => {
  const {
    setLojas,
    lojas,
    isTitulos,
    register,
    control,
    setValue,
    clearErrors,
    errors,
  } = useAceiteDePagamento();
  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Loja
            isMulti
            onChange={(val: number | number[]) => {
              setLojas(val);
            }}
            selectedLoja={lojas}
            isDisabled={isTitulos}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={12}>
          <BuscaParceiro
            name="pessoa"
            label="Parceiro"
            placeholder="Informe pelo menos 3 caracteres do Cliente/Fornecedor/Loja/Transportadora"
            register={register}
            isError={errors.pessoa}
            disabled={isTitulos}
            control={control}
            customOptions={{
              buscarPor: {
                cliente: true,
                fornecedor: true,
                loja: true,
                transportadora: true,
              },
            }}
            style={{ zIndex: 2 }}
            changeSelected={(selected: SelectType) => {
              clearErrors('pessoa');
              setValue('pessoa', selected);
            }}
          />
        </Col>

        <Col md={2} sm={12}>
          <InputNumber2
            label="N.º Docto"
            name="num_docto"
            placeholder="0"
            register={register}
            isError={errors.num_docto}
            disabled={isTitulos}
            maxLength={10}
            max={9999999999}
            control={control}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputMoney
            label="Valor"
            name="val_parcela"
            placeholder="0,00"
            disabled={isTitulos}
            register={register}
            control={control}
            isError={errors.val_parcela}
            min={0.01}
          />
        </Col>
      </Row>
      <Row />
    </>
  );
};
