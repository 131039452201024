import React from 'react';
import { AceiteDePagamentoProvider } from './context/AceiteDePagamentoContext';
import { AceiteDePagamentoContent } from './AceiteDePagamentoContent';

export const AceiteDePagamento: React.FC = () => {
  return (
    <AceiteDePagamentoProvider>
      <AceiteDePagamentoContent />
    </AceiteDePagamentoProvider>
  );
};
