import React, { ChangeEvent } from 'react';
import { Container, ButtonDeafult, SeparatorLine } from './styles';

import { Col, Row } from 'react-bootstrap';
import Separator from '~/components/Separator';
import { AiFillSave } from 'react-icons/ai';
import FooterDefault from '~/components/FooterDefault';
import Tooltip from '@atlaskit/tooltip';
import { HotKeys } from 'react-hotkeys';
import { InputDate } from '~/components/NovosInputs';
import ModalCaptura from './components/ModalCaptura/ModalCaptura';
import { useAceiteDePagamento } from './hooks/useAceiteDePagamento';
import { DataTable } from './components/DataTable/DataTable';
import { AceiteInfos } from './components/AceiteInfos/AceiteInfos';
import { GroupButtons } from './components/GroupButtons/GroupButtons';
import { ActionsButtons } from './components/ActionsButtons/ActionsButtons';
import { SearchInputs } from './components/SearchInputs/SearchInputs';

export const AceiteDePagamentoContent: React.FC = () => {
  const {
    setValue,
    register,
    control,
    com_aceite,
    isTitulos,
    onSave,
    keyMap,
    keyHandlers,
    errors,
    clearErrors,
  } = useAceiteDePagamento();

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <ModalCaptura />
      <Container>
        <h3>Aceite de Pagamento</h3>
        <SeparatorLine />
        <SearchInputs />
        <Row>
          <GroupButtons />
          <Col md={2} sm={12}>
            <InputDate
              control={control}
              register={register}
              name="dta_inicio"
              label="Data de Início"
              isError={errors.dta_inicio}
              disabled={isTitulos}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                clearErrors('dta_inicio');
                setValue('dta_inicio', e.target.value);
              }}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputDate
              control={control}
              register={register}
              name="dta_final"
              label="Data do Fim"
              isError={errors.dta_final}
              disabled={isTitulos}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                clearErrors('dta_final');
                setValue('dta_final', e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <ActionsButtons />
        </Row>
        <Separator />
        <Col md={12} sm={12}>
          <DataTable />
        </Col>
        {!com_aceite && <AceiteInfos />}
        <SeparatorLine />
        <FooterDefault codTela={173}>
          <Col md={12} sm={12} className="mt-2 d-flex justify-content-end">
            <Tooltip position="bottom" content="CTRL + ALT + S">
              <ButtonDeafult
                className="confirm"
                disabled={!isTitulos}
                onClick={() => {
                  onSave();
                }}
              >
                <AiFillSave size={25} /> Gravar
              </ButtonDeafult>
            </Tooltip>
          </Col>
        </FooterDefault>
      </Container>
    </HotKeys>
  );
};
