import React from 'react';
import { FaEraser } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';

export const ActionsButtons: React.FC = () => {
  const {
    isTitulos,
    getTitulosAPagar,
    setIsTitulos,
    clearForm,
    setTitulosApagar,
    clearFormModal,
    setApplyInfo,
    setSubTotal,
  } = useAceiteDePagamento();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          marginTop: '20px',
        }}
      >
        <CustomButtonNovo
          disabled={isTitulos}
          onClick={getTitulosAPagar}
          label="Pesquisar"
          icon={IoIosSearch}
        />
        <CustomButtonNovo
          variant="cancel"
          disabled={!isTitulos}
          onClick={() => {
            setIsTitulos(false);
            setApplyInfo(false);
            setSubTotal(0);
            setTitulosApagar([]);
            clearFormModal();
          }}
          label="Cancelar"
          icon={MdOutlineCancel}
        />
        <CustomButtonNovo
          variant="clear"
          disabled={isTitulos}
          onClick={clearForm}
          label="Limpar"
          icon={FaEraser}
        />
      </div>
    </>
  );
};
